import Entity from '~/models/entity'

export const fields = {
  BRCID: {
    type: Number
  },
  BRID: {
    type: Number
  },
  ContactID: {
    type: Number
  },
  Contact: {
    type: Array,
    fields: {
      CompanyName: {
        type: String
      },
      ContactName: {
        type: String
      },
      EmailAddress1: {
        type: String
      },
      IsSageCustomer: {
        type: Boolean
      },
      IsSageSupplier: {
        type: Boolean
      },
      SageCustomerAcctNo: {
        type: String
      },
      SageSupplierAcctNo: {
        type: String
      },
      NoStorageCharge: {
        type: Boolean
      },
      XeroAccountNumber: {
        type: Boolean
      },
      XeroURL: {
        type: Boolean
      }
    }
  },
  StoragePricingGroup: {
    type: Array,
    fields: {
      SPGID: {
        type: Number
      },
      PricePer9L: {
        type: Number
      },
      FreeMonths: {
        type: Number
      },
      ExternalFreeMonths: {
        type: Number
      },
      Name: {
        type: String
      },
      Default: {
        type: Boolean
      }
    }
  },
  SOID: {
    type: Number
  },
  Bottles: {
    type: Number
  },
  Total: {
    type: Number
  },
  Process: {
    type: Boolean
  },
  Waive: {
    type: Boolean
  },
  Processed: {
    type: Boolean
  },
  Dirty: {
    type: Boolean
  },
  InventoriesToProcess: {
    type: Number
  },
  InventoriesToWaive: {
    type: Number
  },
  InventoriesTotal: {
    type: Number
  },
  PricePer9L: {
    type: Number
  },
  FreeMonths: {
    type: String
  },
  ExternalFreeMonths: {
    type: Number
  },
  PricingGroupName: {
    type: String
  }
}

const billingRunContact = new Entity('billingRunContact', fields)

billingRunContact.createQuery('get', 'billingRunContactGet', fields)
billingRunContact.createQuery(
  'getAll',
  'billingRunContactGetAll',
  {
    total: {
      type: Number
    },
    billingRunContactPaginatedResults: {
      type: Array,
      fields: fields
    }
  },
  {
    // Status: {
    //   type: Number
    // },
    // Search: {
    //   type: String
    // },
    PerPage: {
      type: Number
    },
    Page: {
      type: Number
    }
  }
)

export default billingRunContact

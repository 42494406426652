import {
  getContactsList,
  getContactById,
  searchContact,
  addContact,
  updateContact,
  deleteContactById,
  createWebContactFromContact,
  getContactDocumentsGet,
  deleteContactDocument,
  syncXeroContact,
  getXeroResponse
} from '@/api/contacts/contacts'
import Vue from 'vue'
import { isArray, each, keys } from 'lodash'

export const state = () => ({
  loading: false,
  contactsList: [],
  activeContact: null,
  error: null,
  contactSearchLoading: false,
  contactSearchResults: [],
  contactSearchSelectedItem: null,
  quickSearch: {
    show: {
      term: true,
      status: true,
      type: true,
      profile: true,
      new: false
    },
    filters: {
      wineCard: '',
      term: '',
      status: '',
      type: '',
      profile: ''
    }
  },
  documents: [],
  loadingXeroResponse: false,
  xeroResponse: null
})

export const getters = {
  getContactsList: state => {
    return state.contactsList
  },
  getActiveContact: state => {
    return state.activeContact
  },
  getContactSearchSelectedItem: state => {
    return state.contactSearchSelectedItem
  },
  searchParameters: state => {
    const filters = state.quickSearch.filters
    return {
      SearchTerm: filters.term,
      SupplierWineCardId:
        filters.type === 'suppliers' && filters.wineCard
          ? filters.wineCard.id
          : null,
      CustomerWineCardId:
        filters.type === 'customers' && filters.wineCard
          ? filters.wineCard.id
          : null,
      SearchType: filters.type,
      PromotionProfileCode: filters.profile,
      Active: filters.status
    }
  },
  getDocuments: state => {
    return state.documents
  }
}

export const mutations = {
  setContactsList(state, list) {
    state.contactsList = list
  },
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setLoadingXeroResponse(state, loadingXeroResponse) {
    state.loadingXeroResponse = loadingXeroResponse === true
  },
  setXeroResponse(state, xeroResponse) {
    state.xeroResponse = xeroResponse
  },
  setActiveContact(state, contact) {
    state.activeContact = contact
  },
  setError(state, error) {
    state.error = error
  },
  setContactSearchLoading(state, loading) {
    state.contactSearchLoading = loading === true
  },
  setContactSearchResults(state, results) {
    state.contactSearchResults = results
  },
  resetContactSearchResults(state) {
    state.contactSearchResults = []
  },
  setContactSearchSelectedItem(state, item) {
    state.contactSearchSelectedItem = item
  },
  updateQuickSearchFilters(state, args) {
    const newFilters = state.quickSearch.filters
    if (isArray(args) === false) {
      args = [args]
    }
    each(args, arg => {
      newFilters[arg.filter] = arg.value
    })
    Vue.set(state.quickSearch, 'filters', newFilters)
  },
  updateQuickSearchShowFilters(state, args) {
    const showKeys = keys(state.quickSearch.show)
    const newShown = state.quickSearch.show
    each(showKeys, key => {
      newShown[key] = args.indexOf(key) >= 0
    })
    Vue.set(state.quickSearch, 'show', newShown)
  },
  resetQuickSearchFilters(state) {
    const keys = Object.keys(state.quickSearch.show)
    for (let i = 0; i < keys.length; i++) {
      if (state.quickSearch.show[keys[i]]) {
        state.quickSearch.filters[keys[i]] = ''
      }
    }
  },
  reset(state) {
    state.contactsList = []
    state.activeContact = null
    state.error = null
    state.contactSearchLoading = false
    state.contactSearchResults = []
    state.contactSearchSelectedItem = null
    state.documents = []
  },
  setDocuments(state, documents) {
    state.documents = documents
  }
}

export const actions = {
  async refreshContactsList({ commit }) {
    commit('setLoading', true)
    const response = await getContactsList()
    commit('setContactsList', response.results)
    commit('setLoading', false)
  },
  async loadContactsById({ commit, state }, id) {
    if (state.activeContact && state.activeContact.ContactID === id) {
      return state.activeContact
    }
    commit('setLoading', true)
    try {
      const result = await getContactById(id)
      if (result) {
        commit('setActiveContact', result)
      }
      return result
    } catch (e) {
      console.error('ERROR', e)
      commit('setError', e)
    } finally {
      commit('setLoading', false)
    }
  },
  async contactSearch({ commit, state }, args) {
    commit('resetContactSearchResults')
    commit('setContactSearchLoading', true)
    try {
      const result = await searchContact(args)
      commit('setContactSearchResults', result)
    } catch (e) {
      throw e
    } finally {
      commit('setContactSearchLoading', false)
    }
  },
  async addContact({ commit, dispatch }, contact) {
    commit('setLoading', true)
    try {
      const Contact = await addContact(contact)
      commit('setActiveContact', Contact)
      return Contact
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async updateContact({ commit, dispatch }, contact) {
    commit('setLoading', true)
    try {
      const updatedContact = await updateContact(contact)
      commit('setActiveContact', updatedContact)
      return updatedContact
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async syncXeroContact({ commit, dispatch }, payload) {
    commit('setLoading', true)
    try {
      const updatedContact = await syncXeroContact(payload)
      commit('setActiveContact', updatedContact)
      return updatedContact
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async getXeroResponse({ commit, dispatch }, uuid) {
    commit('setLoadingXeroResponse', true)
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    try {
      let running = true
      let xeroResponse = null
      let backoff = 1

      do {
        await sleep(200 * backoff)
        xeroResponse = await getXeroResponse(uuid)
        commit('setXeroResponse', xeroResponse)
        running = xeroResponse.Running
        backoff = backoff * 2
      } while (running)

      if (xeroResponse.Success === false) {
        throw new Error(xeroResponse.Message)
      }

      return xeroResponse
    } catch (e) {
      throw e
    } finally {
      commit('setLoadingXeroResponse', false)
    }
  },
  async deleteContact({ commit, state }, id) {
    commit('setLoading', true)
    try {
      const response = await deleteContactById(id)
      if (response) {
        if (state.activeContact && state.activeContact.ContactID === id) {
          commit('setActiveContact', null)
        }
        return true
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async getDocuments({ commit }, ContactID) {
    const documents = await getContactDocumentsGet(ContactID)
    if (documents) {
      commit('setDocuments', documents)
    }
  },
  async deleteDocument({ commit, state }, { ContactID, UUID }) {
    commit('setLoading', true)
    try {
      await deleteContactDocument({ ContactID, UUID })
      const documents = state.documents.filter(d => d.UUID !== UUID)
      commit('setDocuments', documents)
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async createWebContactFromContact({ commit }, { ContactID, Password }) {
    commit('setLoading', true)
    try {
      const response = await createWebContactFromContact({
        ContactID,
        Password
      })
      commit('setActiveContact', response)
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  setupFilters({ commit }, { wineCard, term, status, type, profile, show }) {
    const filterArray = [
      {
        filter: 'wineCard',
        value: wineCard
      },
      {
        filter: 'status',
        value: status || ''
      },
      {
        filter: 'type',
        value: type || ''
      },
      {
        filter: 'profile',
        value: profile || ''
      },
      {
        filter: 'term',
        value: term || ''
      }
    ]
    commit('updateQuickSearchFilters', filterArray)
    commit('updateQuickSearchShowFilters', show || [])
  }
}

import { fields as addressFields } from '../address'
const sageFields = {
  IsPosted: {
    type: Boolean
  },
  IsPrinted: {
    type: Boolean
  },
  Enabled: {
    type: Boolean
  },
  UpToDate: {
    type: Boolean
  },
  UpdateDate: {
    type: String
  },
  StatusID: {
    type: Number
  },
  StatusText: {
    type: String
  },
  IsFinalised: {
    type: Boolean
  },
  Exists: {
    type: Boolean
  }
}

const xeroFields = {
  PurchaseOrderNumber: {
    type: String
  },
  XeroPurchaseOrderID: {
    type: String
  },
  XeroURL: {
    type: String
  },
  Status: {
    type: String
  },
  // PaidStatus: {
  //   type: Number
  // },
  // PaidDate: {
  //   type: String
  // },
  UpdatedDate: {
    type: Number
  },
  Enabled: {
    type: Boolean
  }
}

export default {
  POID: {
    type: Number
  },
  ContactID: {
    type: Number
  },
  OrderDate: {
    type: String
  },
  WorkflowStatusID: {
    type: Number
  },
  SupplierOrderNumber: {
    type: String
  },
  ImportID: {
    type: Number
  },
  IsImport: {
    type: Boolean
  },
  CollectionInstructions: {
    type: String
  },
  PaymentTermsID: {
    type: Number
  },
  PaymentTerms: {
    type: String
  },
  SageID: {
    type: Number
  },
  Message: {
    type: String
  },
  UserID: {
    type: Number
  },
  CountryCode: {
    type: String
  },
  InternalNotes: {
    type: String
  },
  PublicNotes: {
    type: String
  },
  AuditCreatedBy: {
    type: Number
  },
  AuditCreatedDate: {
    type: String
  },
  AuditAmendedBy: {
    type: Number
  },
  AuditAmendedDate: {
    type: String
  },
  AuditAmendedByUserName: {
    type: String
  },
  IsLocked: {
    type: Boolean
  },
  PurchaseOrderLinesCount: {
    type: Number
  },
  LastPdfPrintDate: {
    type: String
  },
  AddrID: {
    type: Number
  },
  SageSupplierAcctNo: {
    type: String
  },
  XeroAccountNumber: {
    type: String
  },
  Sage: {
    type: Object,
    fields: sageFields
  },
  Xero: {
    type: Object,
    fields: xeroFields
  },
  InvoiceAddress: {
    type: Object,
    fields: addressFields
  },
  PurchaseOrderTypeID: {
    type: Number
  },
  ReserveSOID: {
    type: Number
  },
  ReserveSOType: {
    type: Number
  }
}

import { getSalesLedger } from '~/api/xero/salesLedger'
import { getAccess } from '~/api/xero/accessToken'
import Vue from 'vue'

export const state = () => ({
  invoices: {},
  loading: false,
  outstanding: {},
  userAccess: null,
  systemAccess: null,
  tenant: null,
  enabled: true
})

export const getters = {
  getSalesLedger: state => ContactID => {
    return state.invoices[ContactID]
  },
  getOutstanding: state => ContactID => {
    return state.outstanding[ContactID]
  },
  getLoading: state => {
    return state.loading
  },
  getAccess: state => {
    return {
      userAccess: state.userAccess,
      systemAccess: state.systemAccess,
      tenant: state.tenant
    }
  },
  getEnabled: state => {
    return state.enabled
  }
}

export const mutations = {
  setLoading(state, loading) {
    state.loading = loading === true
  },
  setSalesLedger(state, { contactID, invoices }) {
    Vue.set(state.invoices, contactID, invoices)
  },
  setOutstanding(state, { contactID, invoices }) {
    const today = new Date()
    Vue.set(
      state.outstanding,
      contactID,
      invoices.reduce((sum, item) => {
        if (item.AmountDue > 0) {
          const paymentDate = new Date(item.ExpectedPaymentDate)
          if (paymentDate < today) {
            return sum + item.AmountDue
          }
        }
        return sum
      }, 0)
    )
  },
  setAccess(state, { UserAccess, SystemAccess, Tenant }) {
    state.userAccess = UserAccess
    state.systemAccess = SystemAccess
    state.tenant = Tenant
  },
  setEnabled(state, enabled) {
    state.enabled = enabled === true
  }
}

export const actions = {
  async getSalesLedger({ commit }, id) {
    commit('setLoading', true)
    try {
      const result = await getSalesLedger(id)
      if (result) {
        commit('setSalesLedger', {
          contactID: id,
          invoices: result.Invoices
        })
        commit('setOutstanding', {
          contactID: id,
          invoices: result.Invoices
        })
        commit('setEnabled', result.Enabled)
      }
    } catch (e) {
      throw e
    } finally {
      commit('setLoading', false)
    }
  },
  async getAccess({ commit }) {
    try {
      const result = await getAccess()
      if (result) {
        commit('setAccess', result)
      }
    } catch (e) {
      throw e
    }
  }
}

import gql from 'graphql-tag'
import { client } from '../graphQLInstance'

export const getSearchResults = async (page, perPage, searchParameters) => {
  // prettier-ignore
  const result = await client.query({
    query: gql`
        query RootQueryType{
          purchaseOrderSearch(
          ${searchParameters.term && searchParameters.term.length > 0 ? `SearchTerm: "${searchParameters.term}",`: '' }
          ${searchParameters.wine && searchParameters.wine.length > 0 ? `WineSearch: "${searchParameters.wine}",`: '' }
          ${searchParameters.from && searchParameters.from.length > 0 ? `FromTime: "${searchParameters.from}",`: '' }
          ${searchParameters.to && searchParameters.to.length > 0 ? `ToTime: "${searchParameters.to}",`: '' }
          ${searchParameters.unallocated ? `Unallocated: true,`: '' }
          ${searchParameters.unsoldStock ? `UnsoldStock: true,`: '' }
          ${searchParameters.wfsOnly ? `WFSOnly: true,`: '' }
          ${searchParameters.orderBy && searchParameters.orderBy.length > 0 ? `OrderBy:"${searchParameters.orderBy}",` : ''}
          ${searchParameters.ContactID && searchParameters.ContactID > 0 ? `ContactID:${searchParameters.ContactID},` : ''}
          ${searchParameters.RelatedContactID && searchParameters.RelatedContactID > 0 ? `RelatedContactID:${searchParameters.RelatedContactID},` : ''}
          ${searchParameters.OnlyReserveOrders ? `OnlyReserveOrders:${searchParameters.OnlyReserveOrders},` : ''}
          ${searchParameters.WithReserves ? `WithReserves:${searchParameters.WithReserves},` : ''}
          Page: ${page},
          PerPage: ${perPage},
          OrderByDirection: ${searchParameters.orderByDirection},
          ) {
            total, 
            purchaseOrderSearchResult{
              POID,
              OrderDate,
              WorkflowStatusDescription,
              LastChangeDate,
              LastPdfPrintDate,
              SageID,
              SageUpdatedDate,
              CompanyName,
              SageSupplierAcctNo,
              OrderValue,
              ListValueUnsoldPct,
              UnsoldWineCount,
              UnsoldAllWineCount,
              UnsoldWFSWineCount,
              UallocatedWineCount,
              EmptyOWCWineCount,
              Selected,
              InternalNotes,
              PublicNotes,
              Initials,
              AuditCreatedDate,
              AuditCreatedBy,
              AuditAmendedDate,
              AuditAmendedBy,
              PurchaseOrderTypeID,
              ReserveSOID,
              ReserveOrderValue,
              XeroURL,
              XeroAccountNumber,
              XeroContactURL
            }
          }
        }
      `
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }

  return result.data.purchaseOrderSearch
}

export const getCategoryList = async searchParameters => {
  // prettier-ignore
  const result = await client.query({
    query: gql`
        query RootQueryType{
          purchaseOrderSearchCategoryList(
          ${searchParameters.term && searchParameters.term.length > 0 ? `SearchTerm: "${searchParameters.term}",`: 'SearchTerm: "",' }
          ${searchParameters.wine && searchParameters.wine.length > 0 ? `WineSearch: "${searchParameters.wine}",`: '' }
          ${searchParameters.from && searchParameters.from.length > 0 ? `FromTime: "${searchParameters.from}",`: '' }
          ${searchParameters.to && searchParameters.to.length > 0 ? `ToTime: "${searchParameters.to}",`: '' }
          ${searchParameters.unallocated ? `Unallocated: true,`: '' }
          ${searchParameters.unsoldStock ? `UnsoldStock: true,`: '' }
          ${searchParameters.wfsOnly ? `WFSOnly: true,`: '' }
          ) {
            CategoryID,
            CategoryName,
            WineCount
          }
        }
      `
  })
  if (result.errors) {
    throw new Error(result.errors[0].message)
  }

  return result.data.purchaseOrderSearchCategoryList
}

import Entity from './entity'

export const salesOrderSearchFields = {
  SOID: {
    type: Number
  },
  OrderDate: {
    type: String
  },
  WorkflowStatusDescription: {
    type: String
  },
  LastChangeDate: {
    type: String
  },
  LastPdfPrintDate: {
    type: String
  },
  SageID: {
    type: Number
  },
  SageUpdatedDate: {
    type: String
  },
  CompanyName: {
    type: String
  },
  SageCustomerAcctNo: {
    type: String
  },
  OrderValue: {
    type: Number
  },
  ReserveOrderValue: {
    type: Number
  },
  StorageOrderValue: {
    type: Number
  },
  Profit: {
    type: Number
  },
  PercentageProfit: {
    type: Number
  },
  CustomerOrderNumber: {
    type: Number
  },
  InternalNotes: {
    type: String
  },
  PublicNotes: {
    type: String
  },
  Initials: {
    type: String
  },
  AuditCreatedDate: {
    type: String
  },
  AuditCreatedBy: {
    type: Number
  },
  AuditAmendedDate: {
    type: String
  },
  AuditAmendedBy: {
    type: Number
  },
  Reference: {
    type: String
  },
  SODSID: {
    type: Number
  },
  SOPSID: {
    type: Number
  },
  SaleOrderTypeID: {
    type: Number
  },
  XeroAccountNumber: {
    type: String
  },
  XeroContactID: {
    type: String
  },
  XeroURL: {
    type: String
  }
}

const fields = {
  current_page: {
    type: Number
  },
  last_page: {
    type: Number
  },
  next_page_url: {
    type: String
  },
  per_page: {
    type: Number
  },
  prev_page_url: {
    type: String
  },
  total: {
    type: Number
  },
  finalised: {
    type: Array
  },
  salesOrderSearchResult: {
    type: Array,
    fields: salesOrderSearchFields
  }
}

const SalesOrderSearch = new Entity('SalesOrderSearch', fields)

SalesOrderSearch.createQuery('get', 'salesOrderSearch')

export default SalesOrderSearch
